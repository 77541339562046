@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto:wght@500&display=swap");

@tailwind base;
html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* loader */

.loader {
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #333;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.loader span {
  position: relative;
  display: inline-block;
  transform-origin: center;
  animation: animate 2s infinite;
}

.loader span:nth-child(1) {
  animation-delay: 0.2s;
}

.loader span:nth-child(2) {
  animation-delay: 0.4s;
}

.loader span:nth-child(3) {
  animation-delay: 0.6s;
}

.loader span:nth-child(4) {
  animation-delay: 0.8s;
}

@keyframes animate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/* loader end */


.playfair-display-font1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.libre-baskerville-regular {
  font-family: "Libre Baskerville", serif;
  /* font-weight: 200; */
  font-style: normal;
}



.charm-regular {
  font-family: "Charm", cursive;
  font-weight: bolder;
  font-style: normal;
}

.montserrat-banner {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}


.lora-about {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size:medium;
}




.ptserif {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: normal;
}