
  
  .slide-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  /* Additional styling can be applied here */
  

  .slick-dots {
    position: absolute;
    bottom: 20px; /* Adjust the value as needed */
    width: 100%;
    text-align: center;
  }
  


  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slide-content {
    animation: slideInFromBottom 3s ease forwards;
  }
  